import Swiper, {
	EffectFade,
	Parallax,
	Pagination,
	Autoplay,
	FreeMode,
	Mousewheel
} from 'swiper'
import { Dropdown, Collapse, Tooltip, Modal } from 'bootstrap'
import 'swiper/css/bundle'
import '../scss/style.scss'
import './contact'
import './filter'
import './departure-form'

var tooltipTriggerList = [].slice.call(
	document.querySelectorAll('[data-bs-show="tooltip"]')
)

tooltipTriggerList.map(el => new Tooltip(el))

Swiper.use([EffectFade, Parallax, Pagination, Autoplay, FreeMode, Mousewheel])

new Swiper('.navbar-carousel .swiper', {
	speed: 800,
	pagination: false,
	effect: 'fade',
	loop: true,
	fadeEffect: {
		crossFade: true
	},
	autoplay: true
})

new Swiper('#home-gallery', {
	mousewheel: true,
	centeredSlides: true,
	slidesPerView: 'auto',
	loop: true,
	freeMode: true,
	autoplay: true
})
