import $ from 'jquery'
import { Modal } from 'bootstrap'
import axios from 'axios'

$('#contact-form').on('submit', e => {
	var $contactModal = document.getElementById('contact-modal')
	e.preventDefault()
	var formData = new FormData(e.target)
	Modal.getOrCreateInstance($contactModal).show()
	axios
		.post(e.target.action, formData)
		.then(({ data }) => {
			e.target.reset()
			$contactModal.querySelector('.modal-body').innerHTML =
				'<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="position:absolute;right:.5rem;top:.5rem;"></button>' +
				data?.msg
		})
		.catch(e => {
			$contactModal.querySelector('.modal-body').innerHTML =
				'<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="position:absolute;right:.5rem;top:.5rem;"></button>' +
				e?.response?.data?.msg
		})
})
