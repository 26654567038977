import $ from 'jquery'

let filters = {}

$('[data-filter-destination]').on('click', function (e) {
	e.preventDefault()
	let $this = $(this)
	$('#dropdownMenuLink_destination').text($this.text())
	filters = {
		...filters,
		destination: $this.data('filter-destination')
	}
})

$('[data-filter-type]').on('click', function (e) {
	e.preventDefault()
	let $this = $(this)
	$('#dropdownMenuLink_type').text($this.text())
	filters = {
		...filters,
		type: $this.data('filter-type')
	}
})

$('[data-filter-group]').on('click', function (e) {
	e.preventDefault()
	let $this = $(this)
	$('#dropdownMenuLink_group').text($this.text())
	filters = {
		...filters,
		group: $this.data('filter-group')
	}
})

$('[data-filter-submit]').on('click', function (e) {
	e.preventDefault()
	e.target.form.action =
		'/' +
		[
			filters.type || 'salidas',
			filters.group || 'todas',
			filters.destination
		]
			.filter(Boolean)
			.join('/')
	e.target.form.submit()
})
